<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-card-text>
      <v-form
        ref="form"
        class="multi-col-validation mt-6"
      >
        <v-row>
          <v-col
            md="4"
            cols="12"
          >
            <v-text-field
              v-model="dataComputed.name"
              :label="t('sports.name')"
              dense
              outlined
              :readonly="option===2"
              :rules="[required]"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            md="4"
          >
            <v-textarea
              v-model="dataComputed.description"
              :label="$t('sports.description')"
              outlined
              rows="2"
              :readonly="option===2"
              :rules="[required]"
            ></v-textarea>
          </v-col>

          <v-col
            cols="12"
            md="4"
          >
            <v-select
              v-model="dataComputed.status"
              dense
              outlined
              :label="t('status.status')"
              :items="statusOptions"
              item-text="text"
              item-value="value"
              :append-icon="option===2 ? null : icons.mdiMenuDown"
              :readonly="option===2"
              :rules="[required]"
            ></v-select>
          </v-col>

          <v-divider class="my-4" />

          <v-col
            cols="12"
            class="pt-3"
            :style="'border-top: 1px solid #00000024;'"
          >
            <v-row>
              <v-col
                cols="12"
                md="4"
              >
                <v-select
                  v-model="newProduct.product_id"
                  dense
                  outlined
                  :label="t('products.product')"
                  :items="productsActiveOptions"
                  :item-text="item => `${item.name} - ${item.measurement_unit}`"
                  item-value="id"
                  :append-icon="option===2 ? null : icons.mdiMenuDown"
                  :readonly="option===2"
                  :rules="[]"
                ></v-select>
              </v-col>

              <v-col
                md="4"
                cols="12"
              >
                <v-text-field
                  v-model.number="newProduct.per_ton"
                  :label="t('products.per_ton')"
                  dense
                  outlined
                  :readonly="option===2"
                  :rules="[]"
                  @keypress="onlyNumberDot"
                ></v-text-field>
              </v-col>

              <v-col
                md="4"
                cols="12"
              >
                <v-btn
                  block
                  color="success"
                  @click="addProduct"
                >
                  {{ $t('tooltip.add') }}
                </v-btn>
              </v-col>

              <v-col cols="12">
                <v-data-table
                  :headers="computedTableColumns"
                  :items="dataComputed.products"
                  item-key="product_id"
                  :loading-text="t('table.loading_text')"
                  :no-data-text="t('table.no_data')"
                  :header-props="{sortByText: t('table.sort_by')}"
                  hide-default-footer
                >
                  <template #[`item.actions`]="{item}">
                    <v-tooltip
                      bottom
                    >
                      <template #activator="{ on: tooltip, attrs }">
                        <v-icon
                          size="20"
                          class="me-2"
                          color="error"
                          v-bind="attrs"
                          v-on="{ ...tooltip }"
                          @click="onDelete(item)"
                        >
                          {{ icons.mdiDeleteOutline }}
                        </v-icon>
                      </template>
                      <span>{{ $t('tooltip.delete') }}</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-col>

          <options-buttom-tab
            :tab-number="tab"
            :is-mode-save-edit="option !== 2"
            :no-save="false"
            :info-required="true"
            :no-cancel="option === 3"
            :no-arrow="true"
            @onCancel="onCancelButton"
            @onNextTab="changeTab"
            @onSave="onSaveAction"
          >
          </options-buttom-tab>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiMenuDown, mdiDeleteOutline } from '@mdi/js'
import { ref, computed, onMounted } from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'
import { required, emailValidator, integerValidator } from '@core/utils/validation'
import { onlyNumberDot } from '@/@core/utils/functionsHelpers'
import { error } from '@core/utils/toasted'
import { getProductsActive, createMixTypeProduct, deleteMixTypeProduct } from '@api'
import OptionsButtomTab from '@/views/components/options-buttom-tab/OptionsButtomTab.vue'

export default {
  components: {
    OptionsButtomTab,
  },
  props: {
    option: {
      type: Number,
      default: 0,
    },
    tab: {
      type: Number,
      default: 0,
    },
    dataParams: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const { t } = useUtils()
    const form = ref(null)
    const dataComputed = computed(() => props.dataParams)

    const productsActiveOptions = ref([])
    const newProduct = ref({
      product_id: null,
      product_name: null,
      product_measurement_unit: null,
      per_ton: null,
    })
    const listTable = ref([])
    const computedTableColumns = computed(() => [
      { text: t('products.product').toUpperCase(), value: 'product_name', show: true },
      { text: t('products.measurement_unit').toUpperCase(), value: 'product_measurement_unit', show: true },
      { text: t('products.per_ton').toUpperCase(), value: 'per_ton', show: true },
      {
        text: t('tooltip.actions').toUpperCase(),
        value: 'actions',
        align: 'center',
        sortable: false,
      },
    ])

    const statusOptions = computed(() => [
      { text: t('status.active'), value: 'A' },
      { text: t('status.inactive'), value: 'I' },
    ])

    const clearNewProduct = () => {
      newProduct.value.product_id = null
      newProduct.value.product_name = null
      newProduct.value.product_measurement_unit = null
      newProduct.value.per_ton = null
    }

    const addProduct = async () => {
      if (newProduct.value.product_id && newProduct.value.per_ton) {
        const finded = productsActiveOptions.value.find(e => e.id === newProduct.value.product_id)
        const body = {
          product_id: newProduct.value.product_id,
          product_name: finded.name,
          product_measurement_unit: finded.measurement_unit,
          per_ton: newProduct.value.per_ton,
        }

        if (props.option === 3) {
          const resp = await createMixTypeProduct({ ...body, mix_type_id: dataComputed.value.id })
          if (resp.ok) dataComputed.value.products.push(body)
          else error(resp.message.text)
        } else { dataComputed.value.products.push(body) }

        clearNewProduct()
      } else {
        error('seleccione un producto y un cantidad')
      }
    }

    const onDelete = async item => {
      if (props.option === 3) {
        const resp = await deleteMixTypeProduct(dataComputed.value.id, item.product_id)
        if (resp.ok) dataComputed.value.products = dataComputed.value.products.filter(e => e.product_id !== item.product_id)
        else error(resp.message.text)
      } else dataComputed.value.products = dataComputed.value.products.filter(e => e.product_id !== item.product_id)
    }

    const onSaveAction = () => {
      if (form.value.validate()) {
        emit('save', dataComputed.value)
      }
    }

    const onCancelButton = () => {
      form.value.reset()
    }

    const changeTab = tab => {
      if (form.value.validate()) {
        emit('changeTab', tab, true, dataComputed.value)
      }
    }

    onMounted(async () => {
      const response = await getProductsActive()
      if (response.ok) {
        productsActiveOptions.value = response.data
      } else {
        productsActiveOptions.value = []
      }
    })

    return {
      // data
      form,
      newProduct,
      listTable,
      productsActiveOptions,

      // computed
      dataComputed,
      statusOptions,
      computedTableColumns,

      // methods
      onCancelButton,
      changeTab,
      onSaveAction,
      addProduct,
      onDelete,

      // validations
      required,
      emailValidator,
      integerValidator,
      onlyNumberDot,

      // i18n
      t,

      icons: {
        mdiMenuDown,
        mdiDeleteOutline,
      },
    }
  },
}
</script>
